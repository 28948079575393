.App {
    text-align: center;
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
   /* background-image: linear-gradient(79deg, #7439db, #C66FBC, #F7944D); */
   color: white;
  }
  
  
  .auth-form-container, .login-form, .registration-form {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    text-align: left;
    border-radius: 10%;
    background-color: #e6e6e6; */
  }
  
  .auth-form-container {
    padding: 5rem;
    border: 1px solid white;
    border-radius: 10px;
    margin: 0.5rem;
  }
  
  label {
    text-align: left;
    padding: 0.25rem 0;
  }
  
  input {
    margin: 0.5rem 0;
    padding: 1rem;
    border: none;
    border-radius: 10px;
  }
  
  /* .login-button {
    border: none;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    color: #7439db;
  } */
  
  .link-btn {
    background: none;
    color: white;
    text-decoration: underline;
  }

 