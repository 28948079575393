@tailwind base;
@tailwind components;
@tailwind utilities;


/* .dark {
  color: white;
  background-color: #20252E;
} */
.dark, body.dark, h1.dark, h2.dark, h3.dark, p.dark {
  color: #FDFDFD;
  background-color: #353D44;
}

.light, body.light, h1.light, h2.light, h3.light, p.light {
  background-color: white;
  color: black;

}h


/* .light {
  color: black;
  background-color: white;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.imgLogo {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

.loginRadious{
  border-radius: 10%;
  
}

.polyImg {
  flex: .8275; 
}


.shoesImg {flex: .587;}


.fbImg {flex: 1.6934;}

 
.loginImg{flex: 1.2;} 


.container {

  margin: 0 auto;
  padding: 5%;
  width: 75%;
  display: flex;

}

.hide {
  display: none;
}

.roundedImg{
  width: 150px;
  height: 150px;
  border-radius: 50%; 
}

@media only screen and (max-width: 700px){
  .container {
    flex-direction: column;
    gap: 10px;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.facebook-card {
  border-radius: 2.5rem;
  padding: 1.5rem;
}


/* 
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
} */

.App-link {
  color: #61dafb;
}
 
/* body {
  --body-bg: blue;

  background-color: var(--primary-page-bg);
  color: var(--primary-text-color);

  --primary-page-bg: #EAEBEB;
  --accent-color: #F05656;

  --pimrary-text-color: #353D44;
  --title-panel-bg: #F2F2F2;
  --snippet-panel-bg: #FDFDFD;
  --read-more-btn-color: var(--snippet-panel-bg);
  --divider-bg: var(--title-panel-bg);
}

body.dark {
  --pimrary-text-color: #FDFDFD;
  --title-panel-bg: #353D44;
  --snippet-panel-bg: #21262B;
  --read-more-btn-bg: #F2F2F2;
  --read-more-btn-color: #21262B;
}

.appTheme.light {
  --body-bg: blue;

  background-color: var(--primary-page-bg);
  color: var(--primary-text-color);

  --primary-page-bg: #EAEBEB;
  --accent-color: #F05656;

  --pimrary-text-color: #353D44;
  --title-panel-bg: #F2F2F2;
  --snippet-panel-bg: #FDFDFD;
  --read-more-btn-color: var(--snippet-panel-bg);
  --divider-bg: var(--title-panel-bg);
}
.appTheme.dark {
  --pimrary-text-color: #FDFDFD;
  --title-panel-bg: #353D44;
  --snippet-panel-bg: #21262B;
  --read-more-btn-bg: #F2F2F2;
  --read-more-btn-color: #21262B;
} */